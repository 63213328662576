<div class="combination-details">
  <div class="recipe-combination-colors-details">
    <div class="detail" *ngIf="combinationDetails.brand">
      <span class="label">{{ 'colorCombinations.combinationDetailsModal.details.label.brand' | translate }}</span>

      <span class="value">{{ combinationDetails.brand }}</span>
    </div>

    <div class="detail" *ngIf="combinationDetails.code">
      <span class="label">{{ 'colorCombinations.combinationDetailsModal.details.label.code' | translate }}</span>

      <span class="value">{{ combinationDetails.code }}</span>
    </div>

    <div class="detail" *ngIf="combinationDetails.name">
      <span class="label">{{ 'colorCombinations.combinationDetailsModal.details.label.name' | translate }}</span>

      <span class="value">{{ combinationDetails.name }}</span>
    </div>
  </div>

  <div class="combination-colors-list">
    <app-table class="recipe-combination-colors-table" [isScrollEnabled]="false">
      <app-table-header>
        <app-table-header-cell class="table-header-cell">
          {{ 'colorCombinations.combinationDetailsModal.table.header.thumbnail' | translate }}
        </app-table-header-cell>

        <app-table-header-cell class="table-header-cell">
          {{ 'colorCombinations.combinationDetailsModal.table.header.code' | translate }}
        </app-table-header-cell>

        <app-table-header-cell class="table-header-cell">
          {{ 'colorCombinations.combinationDetailsModal.table.header.name' | translate }}
        </app-table-header-cell>

        <app-table-header-cell class="table-header-cell">
          {{ 'colorCombinations.combinationDetailsModal.table.header.occurrences' | translate }}
        </app-table-header-cell>
      </app-table-header>

      <ng-container ngProjectAs="app-table-row" *ngIf="combinationDetails">
        <app-table-row *ngFor="let color of combinationDetails.colors" [canToggle]="true" [detailsTemplate]="detailsTemplate">
          <app-table-row-cell>
            <app-color-thumbnail [color]="color.thumbnail"></app-color-thumbnail>
          </app-table-row-cell>

          <app-table-row-cell>{{ color.code }}</app-table-row-cell>

          <app-table-row-cell>{{ color.name }}</app-table-row-cell>

          <app-table-row-cell>{{ color.occurrences }}</app-table-row-cell>

          <ng-template #detailsTemplate>
            <app-combination-color-list-item-details
              [recipeId]="recipeId"
              [repairId]="repairId"
              [id]="combinationDetails.id"
              [colorId]="color.id"
              (openDetailsModalClicked)="openRecipeDetailsModal($event)"
            ></app-combination-color-list-item-details>
          </ng-template>
        </app-table-row>
      </ng-container>
    </app-table>
  </div>
</div>
