import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListModel } from '../../../shared/models/list/list.model';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { RecipesHttpService } from '../recipes-http/recipes-http.service';
import { RecipesListDataPageModel } from '../../models/recipes-list-data-page/recipes-list-data-page.model';
import { RecipesListDataPageDtoCreator } from '../../creators/recipes-list-data-page-dto/recipes-list-data-page-dto.creator';
import { RecipesListDataPageDto } from '../../dtos/recipes-list-data-page/recipes-list-data-page.dto';
import { RecipeListItemDto } from '../../dtos/recipe-list-item/recipe-list-item.dto';
import { RecipeListItemCreator } from '../../creators/recipe-list-item/recipe-list-item.creator';
import { RecipeListItemModel } from '../../models/recipe-list-item/recipe-list-item.model';
import { RecipeFavoriteDataPageModel } from '../../models/recipe-favorite-data-page/recipe-favorite-data-page.model';
import { RecipeFavoriteDataPageDto } from '../../dtos/recipe-favorite-data-page/recipe-favorite-data-page.dto';
import { RecipeFavoriteDataPageDtoCreator } from '../../creators/recipe-favorite-data-page-dto/recipe-favorite-data-page-dto.creator';
import { RecipeFavoriteListItemModel } from '../../models/recipe-favorite-list-item/recipe-favorite-list-item.model';
import { RecipeFavoriteListItemDto } from '../../dtos/recipe-favorite-list-item/recipe-favorite-list-item.dto';
import { RecipeFavoriteListItemCreator } from '../../creators/recipe-favorite-list-item/recipe-favorite-list-item.creator';

@Injectable({
  providedIn: 'root',
})
export class RecipesService {
  constructor(private recipesHttpService: RecipesHttpService) {}

  public getRecipesList(recipesListDataPage: RecipesListDataPageModel): Observable<ListModel<RecipeListItemModel>> {
    const recipesListDataPageDto: RecipesListDataPageDto = RecipesListDataPageDtoCreator.create(recipesListDataPage);

    return this.recipesHttpService.getRecipesList(recipesListDataPageDto).pipe(
      map((listDto: ListDto<RecipeListItemDto>) => {
        const items: Array<RecipeListItemModel> = listDto.items.map((dto: RecipeListItemDto) => RecipeListItemCreator.create(dto));

        return ListCreator.create<RecipeListItemDto, RecipeListItemModel>(listDto, items);
      })
    );
  }

  public getFavoriteRecipesList(recipeFavoriteDataPage: RecipeFavoriteDataPageModel): Observable<ListModel<RecipeFavoriteListItemModel>> {
    const recipeFavoriteDataPageDto: RecipeFavoriteDataPageDto = RecipeFavoriteDataPageDtoCreator.create(recipeFavoriteDataPage);

    return this.recipesHttpService.getFavoriteRecipesList(recipeFavoriteDataPageDto).pipe(
      map((listDto: ListDto<RecipeFavoriteListItemDto>) => {
        const items: Array<RecipeFavoriteListItemModel> = listDto.items.map((dto: RecipeFavoriteListItemDto) =>
          RecipeFavoriteListItemCreator.create(dto)
        );

        return ListCreator.create<RecipeFavoriteListItemDto, RecipeFavoriteListItemModel>(listDto, items);
      })
    );
  }

  public addFavorites(formulaId: number, colorId: number): Observable<void> {
    return this.recipesHttpService.addFavorites(formulaId, colorId);
  }

  public deleteFavorites(formulaId: number, colorId: number): Observable<void> {
    return this.recipesHttpService.deleteFavorites(formulaId, colorId);
  }

  public getRecipeCorrectionGroupClipboardMetrics(groupId: string): Observable<string | null> {
    return this.recipesHttpService.getRecipeCorrectionGroupClipboardMetrics(groupId);
  }
}
