import { RecipesListDataPageModel } from '../../models/recipes-list-data-page/recipes-list-data-page.model';
import { RecipesListDataPageDto } from '../../dtos/recipes-list-data-page/recipes-list-data-page.dto';
import { EffectTypesEnum } from '../../enums/effect-types/effect-types.enum';

export class RecipesListDataPageDtoCreator {
  public static create(model: RecipesListDataPageModel): RecipesListDataPageDto {
    return {
      page: model.page,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.brand && { brand: model.brand }),
      ...(model.model && { model: model.model }),
      ...(model.producerColorCode && { producer_color_code: model.producerColorCode }),
      ...(model.producerColor && { producer_color: model.producerColor }),
      ...(model.colorSystem && { color_system: model.colorSystem }),
      ...(model.colorBook && { color_book: model.colorBook }),
      ...(model.flashcard && { flashcard: model.flashcard }),
      ...(model.measurementId && { measurement_id: model.measurementId }),
      ...(model.clientId && { client_id: model.clientId }),
      ...(model.effectTypes && { effect_types: this.serializeEffectTypes(model.effectTypes) }),
      ...(model.grainSize && { grain_size: model.grainSize }),
    };
  }

  private static serializeEffectTypes(effectTypes: string): Array<string> {
    const values: Array<string> = effectTypes.split(',');

    const indexOfAutomaticOption: number = values.indexOf(EffectTypesEnum.automatic);

    if (indexOfAutomaticOption > -1) {
      values.splice(indexOfAutomaticOption, 1);
    }

    return values;
  }
}
