import { RecipeListItemOverallFitabilityDto } from '../../dtos/recipe-list-item-overall-fitability/recipe-list-item-overall-fitability.dto';
import { RecipeListItemOverallFitabilityModel } from '../../models/recipe-list-item-overall-fitability/recipe-list-item-overall-fitability.model';

export class RecipeListItemOverallFitabilityCreator {
  public static create(dto: RecipeListItemOverallFitabilityDto): RecipeListItemOverallFitabilityModel {
    return {
      hexColor: dto.hex_color,
      value: dto.value,
    };
  }
}
