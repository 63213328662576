import { RecipeFavoriteListItemDto } from '../../dtos/recipe-favorite-list-item/recipe-favorite-list-item.dto';
import { RecipeFavoriteListItemModel } from '../../models/recipe-favorite-list-item/recipe-favorite-list-item.model';

export class RecipeFavoriteListItemCreator {
  public static create(dto: RecipeFavoriteListItemDto): RecipeFavoriteListItemModel {
    return {
      id: dto.id,
      brand: dto.brand,
      model: dto.model,
      producerColorCode: dto.producer_color_code,
      producerColor: dto.producer_color,
      colorSystem: dto.color_system,
      colorbox: dto.color_box,
      multilayer: dto.multilayer,
      colorId: dto.color_id,
      formulaId: dto.formula_id,
      isFavorite: dto.favorite,
      colorBox2k: dto.color_box_2k,
    };
  }
}
