import { Component, Input, OnDestroy } from '@angular/core';

import { take } from 'rxjs/operators';

import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { CombinationDetailsModel } from '../../models/combination-details/combination-details.model';
import { RecipeFormulaBasicModel } from '../../../recipe-details/models/recipe-formula-basic/recipe-formula-basic.model';
import { CombinationAlternativeListItemModel } from '../../models/combination-alternative-list-item/combination-alternative-list-item.model';
import { RecipeDetailsModalService } from '../../../recipe-details/services/recipe-details-modal/recipe-details-modal.service';
import { RecipeDetailsModalParamsModel } from '../../../recipe-details/models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeDetailsTabsService } from '../../../recipe-details/services/recipe-details-tabs/recipe-details-tabs.service';
import { RecipeDetailsTabsEnum } from '../../../recipe-details/enums/recipe-details-tabs/recipe-details-tabs.enum';

@Component({
  selector: 'app-combination-details[combinationDetails]',
  templateUrl: './combination-details.component.html',
  styleUrls: ['./combination-details.component.scss'],
  providers: [RecipeDetailsModalService, RecipeDetailsTabsService],
})
export class CombinationDetailsComponent extends SubscriptionStoreComponent implements OnDestroy {
  @Input() public colorSystemId: number | null;
  @Input() public combinationDetails!: CombinationDetailsModel;
  @Input() public recipeId?: string;
  @Input() public repairId?: string;

  constructor(private recipeDetailsModalService: RecipeDetailsModalService, private recipeDetailsTabsService: RecipeDetailsTabsService) {
    super();

    this.colorSystemId = null;
    this.recipeId = '';
    this.repairId = '';
  }

  public openRecipeDetailsModal(alternative: CombinationAlternativeListItemModel): void {
    if (!this.recipeId) {
      return;
    }

    const formulas: Array<RecipeFormulaBasicModel> = [
      {
        id: alternative.formulaId,
        colorSystemId: this.colorSystemId || 0,
      },
    ];

    const recipe: RecipeDetailsModalParamsModel = {
      id: this.recipeId,
      colorId: alternative.colorId,
      formulas,
    };

    this.recipeDetailsTabsService.setActiveTab(RecipeDetailsTabsEnum.info);

    this.subscription = this.recipeDetailsModalService.openRecipeDetailsModal(recipe, this.colorSystemId).pipe(take(1)).subscribe();
  }
}
