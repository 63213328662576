import { RecipeFavoriteDataPageModel } from '../../models/recipe-favorite-data-page/recipe-favorite-data-page.model';
import { RecipeFavoriteDataPageDto } from '../../dtos/recipe-favorite-data-page/recipe-favorite-data-page.dto';

export class RecipeFavoriteDataPageDtoCreator {
  public static create(model: RecipeFavoriteDataPageModel): RecipeFavoriteDataPageDto {
    return {
      page: model.page,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.brand && { brand: model.brand }),
      ...(model.model && { model: model.model }),
      ...(model.producerColorCode && { producer_color_code: model.producerColorCode }),
      ...(model.producerColor && { producer_color: model.producerColor }),
      ...(model.colorSystem && { color_system: model.colorSystem }),
      ...(model.colorBook && { color_book: model.colorBook }),
      ...(model.flashcard && { flashcard: model.flashcard }),
    };
  }
}
