import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CombinationAlternativeListItemModel } from '../../models/combination-alternative-list-item/combination-alternative-list-item.model';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { ColorCombinationsService } from '../../services/color-combinations/color-combinations.service';
import { ListModel } from '../../../shared/models/list/list.model';
import { CombinationAlternativesListDataPageModel } from '../../models/combination-alternatives-list-data-page/combination-alternatives-list-data-page.dto';
import { ToggleBoxOptionModel } from '../../../forms/models/toggle-box-option/toggle-box-option.model';
import { RecipeTypeModel } from '../../../recipe-details/models/recipe-type/recipe-type.model';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';
import { RecipeDetailsService } from '../../../recipe-details/services/recipe-details/recipe-details.service';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';

@Component({
  selector: 'app-combination-color-list-item-details[id][colorId]',
  templateUrl: './combination-color-list-item-details.component.html',
  styleUrls: ['./combination-color-list-item-details.component.scss'],
})
export class CombinationColorListItemDetailsComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public id!: string;
  @Input() public colorId!: number;
  @Input() public recipeId?: string;
  @Input() public repairId?: string;

  @Output() public openDetailsModalClicked: EventEmitter<CombinationAlternativeListItemModel> =
    new EventEmitter<CombinationAlternativeListItemModel>();

  public combinationColorAlternativesList: Array<CombinationAlternativeListItemModel>;
  public isLoading: boolean;

  public readonly recipeTypeOptions: Array<ToggleBoxOptionModel<RecipeTypeModel>>;

  constructor(
    private readonly colorCombinationsService: ColorCombinationsService,
    private readonly recipeDetailsService: RecipeDetailsService,
    private readonly activeModal: NgbActiveModal,
    private readonly router: Router
  ) {
    super();

    this.combinationColorAlternativesList = [];
    this.isLoading = false;
    this.recipeTypeOptions = this.recipeDetailsService.getRecipeTypeOptions();
    this.openDetailsModalClicked = new EventEmitter<CombinationAlternativeListItemModel>();
  }

  public ngOnInit(): void {
    this.initColorCombinationAlternativesList();
  }

  public openDetailsModal(alternative: CombinationAlternativeListItemModel): void {
    this.openDetailsModalClicked.emit(alternative);
  }

  public goToPrepareMixturePage(alternative: CombinationAlternativeListItemModel): void {
    this.navigateToPrepareMixturePage(RecipeTypeEnum.novolRecipe, alternative);
  }

  private navigateToPrepareMixturePage(recipeType: RecipeTypeModel, alternative: CombinationAlternativeListItemModel): void {
    if (!this.recipeId) {
      return;
    }

    this.subscription = this.recipeDetailsService
      .getRecipeDetailsToPrepareMixture(recipeType, alternative.colorId, alternative.formulaId, this.recipeId, this.repairId)
      .subscribe((prepareMixtureState: PrepareMixtureStateModel) => {
        const locationState: CanDeactivateGuardModel<PrepareMixtureStateModel> = {
          canDeactivate: true,
          data: prepareMixtureState,
        };

        this.router.navigate(['/mixes/prepare-mixture'], { state: LocationGetStateUtil.createState(locationState) }).then(() => {
          this.activeModal.dismiss();
        });
      });
  }

  private initColorCombinationAlternativesList(): void {
    const combinationColorAlternativesListDataPage: CombinationAlternativesListDataPageModel = { page: 1 };

    this.setIsLoading(true);

    this.subscription = this.colorCombinationsService
      .getCombinationAlternativesList(this.id, this.colorId, combinationColorAlternativesListDataPage)
      .pipe(finalize(() => this.setIsLoading(false)))
      .subscribe({
        next: (list: ListModel<CombinationAlternativeListItemModel>) => {
          this.combinationColorAlternativesList = list.items;
        },
      });
  }

  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
