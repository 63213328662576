import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HighchartsChartModule } from 'highcharts-angular';

import { LineChartComponent } from './components/line-chart/line-chart.component';

@NgModule({
  declarations: [LineChartComponent],
  imports: [CommonModule, HighchartsChartModule],
  exports: [LineChartComponent],
})
export class ChartsModule {}
