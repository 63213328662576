<app-table-row-details class="combination-color-list-item-details" *appLoading="isLoading">
  <ng-container table-row-details-content>
    <div class="combination-color-alternatives-list">
      <div class="combination-color-alternatives-list-row" *ngFor="let alternative of combinationColorAlternativesList">
        <div>
          <app-color-thumbnail [color]="alternative.thumbnail"></app-color-thumbnail>
        </div>

        <div>{{ alternative.colorCode }}</div>

        <div>{{ alternative.colorName }}</div>

        <div>{{ alternative.alternative }}</div>

        <div class="buttons-container">
          <button class="button red-link" (click)="openDetailsModal(alternative)">
            <span>{{ 'colorCombinations.combinationColorListItemDetails.table.row.buttons.recipeDetails' | translate }}</span>

            <span class="icon info-circle-gray-icon"></span>
          </button>

          <button class="button red-link" (click)="goToPrepareMixturePage(alternative)">
            <span>{{ 'colorCombinations.combinationColorListItemDetails.table.row.buttons.goToPrepareMixturePage' | translate }}</span>

            <span class="icon mix-gray-icon"></span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</app-table-row-details>
