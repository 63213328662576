import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { CombinationDetailsComponent } from './components/combination-details/combination-details.component';
import { ModalModule } from '../modal/modal.module';
import { SharedModule } from '../shared/shared.module';
import { CombinationColorListItemDetailsComponent } from './components/combination-color-list-item-details/combination-color-list-item-details.component';
import { ExportCombinationsToPdfComponent } from './components/export-combinations-to-pdf/export-combinations-to-pdf.component';
import { RecipeDetailsModalService } from '../recipe-details/services/recipe-details-modal/recipe-details-modal.service';
import { ModalService } from '../modal/services/modal.service';

@NgModule({
  declarations: [CombinationDetailsComponent, CombinationColorListItemDetailsComponent, ExportCombinationsToPdfComponent],
  exports: [CombinationDetailsComponent, ExportCombinationsToPdfComponent],
  imports: [CommonModule, ModalModule, SharedModule, TranslateModule],
  providers: [RecipeDetailsModalService, ModalService],
})
export class ColorCombinationsModule {}
