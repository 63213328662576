import { Component, Input } from '@angular/core';

import { ColorCombinationsService } from '../../services/color-combinations/color-combinations.service';
import { SubscriptionStoreComponent } from '../../../shared/components/subscription-store/subscription-store.component';
import { FileUtil } from '../../../shared/utils/file/file.util';
import { DownloadFileModel } from '../../../shared/models/download-file/download-file.model';

@Component({
  selector: 'app-export-combinations-to-pdf[id]',
  templateUrl: './export-combinations-to-pdf.component.html',
  styleUrl: './export-combinations-to-pdf.component.scss',
})
export class ExportCombinationsToPdfComponent extends SubscriptionStoreComponent {
  @Input() public id!: string;
  @Input() public isDisabled: boolean;

  constructor(private colorCombinationsService: ColorCombinationsService) {
    super();

    this.isDisabled = false;
  }

  public exportToPdf(): void {
    this.subscription = this.colorCombinationsService.exportCombinationToPdf(this.id).subscribe({
      next: (downloadFile: DownloadFileModel) => {
        FileUtil.downloadFile(downloadFile);
      },
    });
  }
}
